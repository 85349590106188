import { ArrowDiagonalIcon, CopyIcon } from '@assets/icons'
import EbillTextSkeleton from '@components/Skeletons/EbillTextSkeleton'
import { getNewestInvoice, getFormattedBillingPeriod } from '@helpers/invoices'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { useInvoices } from '@hooks/useInvoices/useInvoices'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import { Button, Chip, Link, styled, Typography, Stack, IconButton, useTheme, Box } from '@mui/material'
import InvoiceSettingsWrapper from '@src/ui/wrappers/InvoiceSettingsWrapper/InvoiceSettingsWrapper'
import Show from '@src/ui/wrappers/Show/Show'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.green['600'],
  color: 'white',
  fontFamily: 'GothamMedium',
  fontSize: '16px',
  marginRight: 3,
  width: 26,
  height: 26,
  '& .MuiChip-label': {
    lineHeight: '17px',
  },
}))

const EBill = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { activeContracts } = useLocationsData()
  const { invoices, isLoading } = useInvoices({})

  const lastInvoice = useMemo(() => getNewestInvoice(invoices), [invoices])
  const billingPeriod = getFormattedBillingPeriod(lastInvoice?.billingPeriodFromDate, lastInvoice?.billingPeriodToDate)

  const handleOnCopy = async (text?: string) => {
    if (text) {
      await navigator.clipboard.writeText(text)
    }
  }

  return (
    <InvoiceSettingsWrapper
      buttonTitle={t('back')}
      link={t('navigation_url.invoices_settings')}
      title={t('e_bill.e_bill')}
      sx={{ gap: 0 }}
    >
      <Typography variant="bodyRegular" maxWidth={680}>
        {t('e_bill.ebill_explanation')}
      </Typography>

      <Typography variant="bodyRegular" fontFamily="GothamMedium">
        {t('e_bill.steps_for_activation')}
      </Typography>

      <Typography variant="bodyRegular" fontFamily="GothamMedium">
        <StyledChip size="small" label="1" /> {t('e_bill.title_login_online_banking')}
      </Typography>
      <Typography variant="bodyRegular" maxWidth={680} pl={4}>
        {t('e_bill.explanation_login_online_banking')}
      </Typography>

      <Typography variant="bodyRegular" fontFamily="GothamMedium">
        <StyledChip size="small" label="2" /> {t('e_bill.title_active_ebill')}
      </Typography>
      <Typography variant="bodyRegular" maxWidth={680} pl={4}>
        <Trans i18nKey="e_bill.explanation_active_ebill" />
      </Typography>
      <Typography variant="bodyRegular" fontFamily="GothamMedium">
        <StyledChip size="small" label="3" /> {t('register')}
      </Typography>

      <Typography variant="bodyRegular" maxWidth={680} pl={4}>
        <Trans i18nKey="e_bill.explanation_register_ebill" />
      </Typography>

      <Show when={isLoading}>
        <EbillTextSkeleton />
      </Show>

      <Show when={!activeContracts.length && !isLoading}>
        <Box ml={4}>
          <Typography variant="bodyRegular">{t('invoices_settings.eg_customer_number')}:</Typography>
          <Typography>1001843</Typography>

          <Typography variant="bodyRegular">{`${t('invoices_settings.eg_invoice_number')} (01.05.-31.08.23):`}</Typography>
          <Typography>630807337</Typography>
        </Box>
      </Show>

      <Show when={!!activeContracts.length && !isLoading}>
        <Box ml={4}>
          <Typography variant="bodyRegular" sx={{ my: 1 }}>
            {t('customer_number')}:
          </Typography>
          <Stack direction="row" gap={1}>
            <Typography>{activeAccount.gpnr}</Typography>
            <IconButton onClick={() => handleOnCopy(activeAccount.gpnr)}>
              <CopyIcon sx={{ fill: theme.palette.common.white }} />
            </IconButton>
          </Stack>

          <Typography
            variant="bodyRegular"
            sx={{ my: 1 }}
          >{`${t('invoices.invoice_number')} ${billingPeriod}`}</Typography>
          <Stack direction="row" gap={1}>
            <Typography>{lastInvoice?.invoiceId}</Typography>
            <IconButton onClick={() => handleOnCopy(lastInvoice?.invoiceId)}>
              <CopyIcon sx={{ fill: theme.palette.common.white }} />
            </IconButton>
          </Stack>
        </Box>
      </Show>

      <Button
        variant="text"
        LinkComponent={Link}
        href="https://www.ckw.ch/landingpages/ebill"
        target="_blank"
        sx={{ maxWidth: 'fit-content' }}
        endIcon={<ArrowDiagonalIcon />}
      >
        {t('e_bill.to_ebill_info')}
      </Button>
    </InvoiceSettingsWrapper>
  )
}

export default EBill
