import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios'

// import { keycloak } from '../context/AuthContextProvider'

export const createApiInstance = (baseURL: string) => {
  const apiInstance: AxiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  apiInstance.interceptors.request.use(async (request) => {
    await setAccessTokenOnRequestAndAsAxiosDefaults(apiInstance, request)
    return request
  }, Promise.reject)

  apiInstance.interceptors.response.use(
    async (response) => {
      return response
    },
    async (error: AxiosError) => {
      if (error.response !== undefined && error.response.status === 401) {
        // await keycloak.logout()
      }
      throw error
    }
  )

  return apiInstance
}

export const setAccessTokenOnRequestAndAsAxiosDefaults = async (
  axiosInstance: AxiosInstance,
  request: AxiosRequestConfig
) => {
  const token = localStorage.getItem('ckw-token')
  if (token) {
    const authHeader = `Bearer ${JSON.parse(token)}`
    if (!request.headers) request.headers = {}
    request.headers.Authorization = authHeader
    axiosInstance.defaults.headers.common['Authorization'] = authHeader
  }
}

const api = createApiInstance(process.env.REACT_APP_API_URL as string)
const apiLocations = createApiInstance(process.env.REACT_APP_LOCATIONS_API_URL as string)
const apiAccount = createApiInstance(process.env.REACT_APP_ACCOUNT_API_URL as string)
const apiUser = createApiInstance(process.env.REACT_APP_USER_API_URL as string)
const apiInvoice = createApiInstance(process.env.REACT_APP_INVOICE_API_URL as string)
const apiAuthorization = createApiInstance(process.env.REACT_APP_AUTHORIZATION_URL as string)

export { api, apiLocations, apiAccount, apiUser, apiInvoice, apiAuthorization }
