import FooterNavigation from '@components/Navigation/FooterNavigation'
import { SuspenseLoading } from '@components/SuspenseLoading/SuspenseLoading'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { useFeatureToggle } from '@hooks/useFeatureToggle/useFeatureToggle'
import { Stack, styled } from '@mui/material'
import MaintenancePage from '@pages/Maintenance/MaintenancePage'
import { Suspense } from 'react'

import { useAuth } from '../../../hooks/useAuth/useAuth'
import { PublicRoutes, Routes } from '../../../router/Routes'
import Navigation from '../../components/Navigation/Navigation'

const AppWrapper = styled(Stack)({
  minHeight: '100vh',
  width: '100%',
})

const PrivateRoutes = () => {
  const { features } = useFeatureToggle()
  const isMaintenance = features['maintenance']

  return (
    <>
      <Navigation />
      {isMaintenance ? <MaintenancePage /> : <Routes />}
      <FooterNavigation />
    </>
  )
}

export const AppContainer = () => {
  const { keycloakError } = useAuth()
  const { isServerError } = useAccounts()

  return (
    <Suspense fallback={<SuspenseLoading />}>
      <AppWrapper>{keycloakError || isServerError ? <PublicRoutes /> : <PrivateRoutes />}</AppWrapper>
    </Suspense>
  )
}
