import { Stack, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

const NoAccountControls = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation()
  return (
    <Stack direction="row" alignItems="center" mt={3} flexWrap="wrap" columnGap={1}>
      <Typography variant="bodyRegular" sx={{ m: 0 }}>
        {t('authorization.do_you_need_help')}
      </Typography>
      <Button variant="text" disableTouchRipple size="large" sx={{ p: '0 !important' }} onClick={onClick}>
        {t('authorization.contact_support')}
      </Button>
    </Stack>
  )
}

export default NoAccountControls
