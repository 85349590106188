import { Stack, Skeleton } from '@mui/material'

const EbillTextSkeleton = () => {
  return (
    <Stack gap={1} ml={4}>
      <Skeleton width={'50%'} />
      <Skeleton width={'50%'} />
      <Skeleton width={'50%'} />
      <Skeleton width={'30%'} />
      <Skeleton width={'20%'} />
      <Skeleton width={'40%'} />
      <Skeleton width={'30%'} />
    </Stack>
  )
}

export default EbillTextSkeleton
