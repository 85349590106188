import { ArrowDiagonalIcon, ArrowRightIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import { Box, Typography, styled } from '@mui/material'
import Grid from '@mui/material/Grid2'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const { t } = i18n

const links = [
  {
    title: t('invoices.link_explanation_invoice_short'),
    link: 'https://www.ckw.ch/kontakt/rechnungen-und-zahlungen',
    target: '_blank',
    icon: <ArrowDiagonalIcon />,
  },
  {
    title: 'Fragen und Antworten',
    link: '/',
    target: '_self',
    icon: <ArrowRightIcon sx={{ fill: theme.palette.green['600'] }} />,
  },
  {
    title: t('personal_data.personal_data_report_relocation'),
    link: 'https://www.ckw.ch/kontakt/umzug',
    target: '_blank',
    icon: <ArrowDiagonalIcon />,
  },
  {
    title: t('invoices.link_activate_ebill'),
    link: `${t('navigation_url.invoices_settings')}/${t('e_bill.e_bill')}`,
    target: '_self',
    icon: <ArrowRightIcon sx={{ fill: theme.palette.green['600'] }} />,
  },
]

const FrButton = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: 6,
  padding: theme.spacing(2, 3),
  border: `1px solid ${theme.palette.gray['200']}`,
  textDecoration: 'none',
  borderRadius: 24,
}))

const DtpFrequentRequests = () => {
  const { t } = useTranslation()
  return (
    <Box mt={5}>
      <Typography variant="heading4">{t('frequent_requests')}</Typography>
      <Grid container spacing={2} maxWidth={746} mt={3}>
        {links.map(({ link, icon, target, title }, index) => (
          <Grid size={{ xs: 12, sm: 6 }} key={`${title}${index}`}>
            <FrButton to={link} target={target}>
              <Typography variant="bodyRegularEmphasized" sx={{ my: 0 }} color="primary">
                {title}
              </Typography>
              {icon}
            </FrButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default DtpFrequentRequests
