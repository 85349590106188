import DtpToast from '@components/DtpToast/DtpToast'
import { toast } from 'react-toastify'

type SeverityType = 'error' | 'info' | 'warning' | 'success'
interface INotificationOptions {
  severity?: SeverityType
  autoHideDuration?: number
}

const useNotification = () => {
  return (severity: SeverityType, title?: string, message?: string, options?: INotificationOptions) => {
    return toast[severity](
      <DtpToast
        title={title}
        message={message}
        severity={severity}
        autoHideDuration={options?.autoHideDuration ?? 5000}
      />
    )
  }
}

export default useNotification
