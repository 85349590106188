import { withErrorLogging } from '@helpers/hof'
import { IAccountDetails } from '@interfaces/accounts'
import { IAuthorizeUserApiResponse } from '@interfaces/authorization'

import { apiUser, apiInvoice, apiAccount, apiAuthorization } from './index'

export const setMainAccount = withErrorLogging(async ({ gpnr }: { gpnr: string }) => {
  const { data } = await apiUser.post(
    '/v1/user',
    {
      configuration: {
        mainAccount: gpnr,
      },
    },
    {
      headers: {
        gpnr,
      },
    }
  )
  return data
})

export const downloadInvoice = async ({ gpnr, invoiceId }: { gpnr: string; invoiceId: string }) => {
  const { data } = await apiInvoice.post('/v1/invoice/pdf', { invoiceId }, { responseType: 'blob', headers: { gpnr } })
  return data
}

export const updateAccountFields = async ({
  gpnr,
  updatedDetails,
}: {
  gpnr: string
  updatedDetails: IAccountDetails
}): Promise<IAccountDetails> => {
  const { data } = await apiAccount.put('/v1/account/details', updatedDetails, { headers: { gpnr } })
  return data
}

export const updateInvoiceSettings = async ({
  gpnr,
  contractAccountId,
  paymentDeliveryType,
}: {
  gpnr: string
  contractAccountId: string
  paymentDeliveryType: string
}) => {
  const { data } = await apiInvoice.put(
    '/v1/invoice/settings',
    { contractAccountId, paymentDeliveryType },
    { headers: { gpnr } }
  )
  return data
}

export const deleteAccount = async ({ gpnr }: { gpnr: string }) => {
  const { data } = await apiAuthorization.delete('/v1/authorization/gpnr', { headers: { gpnr } })
  return data
}

export const invoiceEmailInitiation = async () => {
  const { data } = await apiAuthorization.post('/v1/authorization/via-invoice-email-initiation')
  return data
}

export const invoiceEmailConfirmation = async ({ otp }: { otp: string }) => {
  const { data } = await apiAuthorization.post('/v1/authorization/via-invoice-email-confirmation', { otp })
  return data
}

export const authorizeUserAccount = async ({
  gpnr,
  evidence,
}: {
  gpnr: string
  evidence: string
}): Promise<IAuthorizeUserApiResponse> => {
  const { data } = await apiAuthorization.post('/v1/authorization/validation', { evidence }, { headers: { gpnr } })
  return data
}

export const requestAccessCodeMethod = async ({
  gpnr,
  medium,
}: {
  gpnr: string
  medium: 'mobileNumber' | 'invoiceEmail'
}) => {
  const { data } = await apiAuthorization.post('/v1/authorization/self-service', { medium }, { headers: { gpnr } })
  return data
}
