import { FormSteps } from '@components/DtpConnectAccountWizard/DtpConnectAccountWizard'
import Wrapper from '@components/DtpConnectAccountWizard/common/Wrapper'
import useNotification from '@hooks/useNotification/useNotification'
import { Box, Typography, Button, Stack } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'

export interface IResendEmailProps {
  goTo: (step: string) => void
  setOtp: (otp: string) => void
  emailInitiation: () => Promise<{ body: { otpValue: string } }>
}

const ResendEmail = ({ goTo, emailInitiation, setOtp }: IResendEmailProps) => {
  const { t } = useTranslation()
  const notification = useNotification()

  const handleEmailInitiation = async () => {
    try {
      const { body } = await emailInitiation()
      setOtp(body.otpValue)
      goTo(FormSteps.AUTOMATIC_AUTH_OTP)
    } catch (error) {
      notification('error')
    }
  }
  return (
    <Wrapper>
      <Box>
        <Box mb={4}>
          <Typography variant="heading3">{t('authorization.confirmation_link_sent')}</Typography>
          <Typography variant="bodyRegular">{t('authorization.automatic_email_authorization_description')}</Typography>
        </Box>

        <Button size="large" onClick={handleEmailInitiation}>
          {t('authorization.resend_email')}
        </Button>

        <Stack mt={3}>
          <Typography variant="bodyRegular" sx={{ m: 0 }}>
            <Trans
              i18nKey="authorization.go_to_manual_authorization"
              components={{
                button: (
                  <Button
                    variant="text"
                    disableTouchRipple
                    size="large"
                    sx={{ p: '0 !important', minWidth: { xs: 'fit-content' } }}
                    onClick={() => goTo(FormSteps.ACCOUNT_NUMBER_STEP)}
                  />
                ),
              }}
            />
          </Typography>
        </Stack>
      </Box>
    </Wrapper>
  )
}

export default ResendEmail
