import { Typography, Stack, Box, useTheme } from '@mui/material'

interface IMethodSelectCard {
  icon?: React.ReactNode
  title?: string
  description?: string
  descriptionColor?: string
}

const MethodSelectCard = ({ icon, title, description, descriptionColor }: IMethodSelectCard) => {
  const theme = useTheme()
  return (
    <Stack
      direction="row"
      py={2}
      px={3}
      bgcolor={theme.palette.gray['50']}
      border={`1px solid ${theme.palette.gray['100']}`}
      borderRadius={1}
      columnGap={2}
      my={2}
    >
      {icon}
      <Box>
        <Typography variant="bodyRegularEmphasized" sx={{ mt: 0 }}>
          {title}
        </Typography>
        <Typography color={descriptionColor} variant="bodyRegular">
          {description}
        </Typography>
      </Box>
    </Stack>
  )
}

export default MethodSelectCard
