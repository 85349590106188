import { Skeleton, Stack, SxProps } from '@mui/material'

const DtpCardSelectSkeleton = ({ sx }: { sx?: SxProps }) => {
  return (
    <Stack
      direction="row"
      maxWidth={550}
      padding={'17px'}
      pl={3}
      columnGap={2}
      mb={2}
      sx={{ border: '1px solid #EFF0EF', ...sx }}
    >
      <Skeleton variant="circular" width={24} height={24} />
      <Skeleton width="60%" />
    </Stack>
  )
}

export default DtpCardSelectSkeleton
