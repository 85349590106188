import DtpCardSelect, { DtpCardSelectItem } from '@components/DtpCardSelect'
import { AuthOptions } from '@enums/authorization'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ISelectDeliveryOptionProps {
  authOptions: Partial<Record<AuthOptions, string>>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectedOption: AuthOptions
}

const SelectDeliveryOption = ({ authOptions, onChange, selectedOption }: ISelectDeliveryOptionProps) => {
  const { t } = useTranslation()
  return (
    <DtpCardSelect value={selectedOption} onChange={onChange}>
      <DtpCardSelectItem
        label={
          <>
            <Typography sx={{ m: 0 }}>{t('authorization.by_sms')}</Typography>
            <Typography variant="bodyRegular" sx={{ m: 0 }}>
              {`${t('authorization.to')} ${authOptions.mobileNumber}`}
            </Typography>
          </>
        }
        checked={selectedOption === 'mobileNumber'}
        value={'mobileNumber'}
      />
      <DtpCardSelectItem
        label={
          <>
            <Typography sx={{ m: 0 }}>{t('authorization.by_e_mail')}</Typography>
            <Typography variant="bodyRegular" sx={{ m: 0 }}>
              {`${t('authorization.to')} ${authOptions.invoiceEmail}`}
            </Typography>
          </>
        }
        checked={selectedOption === 'invoiceEmail'}
        value={'invoiceEmail'}
      />
    </DtpCardSelect>
  )
}

export default SelectDeliveryOption
