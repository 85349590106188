import { chartPallete, comparisonPalette, prosumerPalette, switchComponentBackground } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import ConsumptionItem from '@components/ConsumptionItem/ConsumptionItem'
import ProsumerItem from '@components/ProsumerItem/ProsumerItem'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { usePrevious } from '@hooks/usePrevious'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Stack, Typography, Divider, Box, Button, styled, useMediaQuery } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface PayloadItem {
  payload: {
    timestampStartOfPeriod: string
    amountLowRate: string | null
    amountLowRateQuality?: string
    amountLowRateCharged?: string
    amountHighRate: string | null
    amountHighRateQuality?: string
    amountHighRateCharged?: string
    forecast?: string
    forecastCharge?: string
    sumOfRates: string
    feedIn?: string | number
    consumption?: string | number
    balance?: string | number
    energy?: string
    energyChf?: string
    energyMeasured?: string
    averageEnergy?: string
    averageMaxPower?: string
    maxPower?: string
    reactivePower?: string
    description?: string
    noDataValue?: number | null
  }
}

interface CustomTooltipProps {
  active?: boolean
  payload?: PayloadItem[]
  unit: string
  setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>
  tooltipPosition: number
  chartType?: string
  aggregationType?: string
  handleOnDateChange?: CallableFunction
  chartOrigin?: 'ChartArea' | 'ChartBar' | 'ComparationChart'
  id?: string
  activeBar?: string
  maxPower?: {
    timestamp: string
    maxPower: string | number
  }
}
interface CustomEvent extends MouseEvent {
  target: EventTarget & {
    nodeName: string
  }
}

const CustomBox = styled(Stack)({
  position: 'relative',
  border: `1px solid ${switchComponentBackground}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.borderRadius.medium,
  padding: 24,
  pointerEvents: 'all',
  boxShadow: '0px 2.058px 1.934px 0px rgba(0, 0, 0, 0.0022)',
})

const CustomButton = styled(Button)({
  fontSize: '16px',
  letterSpacing: '0em',
  padding: 0,
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
})

export const ChartTooltip = ({
  active,
  payload,
  unit,
  tooltipPosition,
  chartType,
  aggregationType,
  setTooltipOpen,
  handleOnDateChange,
  chartOrigin,
  id,
  activeBar,
  maxPower,
}: CustomTooltipProps) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement | null>(null)
  const [currentTooltipPosition, setCurrentTooltipPosition] = useState(0)
  const [currentPayload, setCurrentPayload] = useState<PayloadItem[] | undefined>(payload)
  const previousTooltipPosition = usePrevious(currentTooltipPosition)
  const isSmallMobile = useMediaQuery(theme.breakpoints.between('xs', 500))

  const handleClickOutside = (event: MouseEvent) => {
    const customEvent: CustomEvent = event as CustomEvent
    if (customEvent.target.nodeName === 'rect' && chartOrigin === 'ComparationChart') return
    ref.current &&
      !ref.current.contains(customEvent.target as Node) &&
      customEvent.target.nodeName !== 'path' &&
      setTooltipOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    setCurrentTooltipPosition(tooltipPosition)
  }, [tooltipPosition])

  useEffect(() => {
    if ((previousTooltipPosition !== tooltipPosition && previousTooltipPosition !== 0) || isSmallMobile) {
      setCurrentPayload(payload)
    }
  }, [previousTooltipPosition, tooltipPosition, payload, isSmallMobile])

  if (active && currentPayload?.length) {
    const lowRateValue = currentPayload[0].payload.amountLowRate
    const highRateValue = currentPayload[0].payload.amountHighRate
    const forecastValue: string = currentPayload[0].payload.forecast ?? '0'
    const sumOfRatesValue: string = currentPayload[0].payload.sumOfRates
    const noDataValue = currentPayload[0].payload.noDataValue
    const feedIn = currentPayload[0].payload.feedIn
    const consumption = currentPayload[0].payload.consumption
    const energy = currentPayload[0].payload.energy
    const balance = currentPayload[0].payload.balance

    const comparisonPastConsumption = consumption
    const comparisonForecastConsumption = forecastValue
    const comparisonCurrentConsumption = consumption
    const comparisonEstimatedConsumption: number =
      Number(comparisonForecastConsumption) + Number(comparisonPastConsumption)

    const amountForecastIsZero = !!(forecastValue && Number(forecastValue) === 0)
    const comparisonForecastConsumptionIsZero = !!(
      comparisonForecastConsumption && Number(comparisonForecastConsumption) === 0
    )
    const comparisonCurrentConsumptionIsZero = !!(
      comparisonCurrentConsumption && Number(comparisonCurrentConsumption) === 0
    )

    const noDataValueInBarChart = chartOrigin === 'ChartBar' || !noDataValue

    const isProsumer = !!(feedIn || Number(feedIn) === 0) || !!balance
    const isConsumer = !!lowRateValue || !!highRateValue || !!consumption || !!energy
    // TODO: this is removed based on the story dtp-819 leaved if it needs to bring back
    // const prosumerBalance = Number(feedIn) - Number(consumption) || Number(feedIn) || Number(consumption)

    const selectedTime = dayjs(currentPayload[0].payload.timestampStartOfPeriod)
    const selectedTimeInterval = selectedTime.add(1, 'hour')
    const chartAreaTooltip = !!(chartOrigin && chartOrigin === 'ChartArea')
    const chartBarTooltip = !!(chartOrigin && chartOrigin === 'ChartBar')
    const comparisonChartTooltip = !!(chartOrigin && chartOrigin === 'ComparationChart')
    const paddingInComparationChart = chartOrigin === 'ComparationChart' ? { padding: '16px' } : { padding: '24px' }
    const dateFormatForMonth = chartType === chartAxisEnum.MONTH || aggregationType === 'month' ? 'MMMM YYYY' : 'YYYY'
    const translateForMonthAndDay =
      chartType === chartAxisEnum.MONTH || aggregationType === 'month'
        ? t('power_consumption_page.go_to_monthly_view')
        : t('power_consumption_page.go_to_daily_view')
    const tooltipDescription = (
      <Typography sx={{ fontSize: '16px' }} variant="bodyRegular">
        *{t(`power_consumption_page.${currentPayload[0].payload.description}`)}
      </Typography>
    )
    return (
      <Box ref={ref} id={id}>
        <CustomBox
          sx={
            chartOrigin && chartOrigin === 'ChartArea'
              ? { padding: '16px', minWidth: '228px', maxWidth: 'max-content' }
              : paddingInComparationChart
          }
          id="customBox"
        >
          {chartType || aggregationType ? (
            <Typography variant="body1" sx={{ margin: '0 0 5px 0' }}>
              {dayjs(currentPayload[0].payload.timestampStartOfPeriod).format(
                chartType === chartAxisEnum.DAY ? 'DD MMM YYYY' : dateFormatForMonth
              )}
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ margin: '0 0 5px 0' }}>
              {`${selectedTime.format('HH:mm')} - ${selectedTimeInterval.format('HH:mm')} ${t('hour')}`}
            </Typography>
          )}
          <Box>
            <Show when={chartOrigin !== 'ComparationChart'}>
              <Show when={(lowRateValue || highRateValue) === null}>
                {t('power_consumption_page.error_message_no_data_available')}
              </Show>
              <Show when={isConsumer && !isProsumer}>
                <Box>
                  <Show when={(chartAreaTooltip || chartBarTooltip || !chartOrigin) && !energy}>
                    <ConsumptionItem
                      label={t('power_consumption_page.low_rate')}
                      value={lowRateValue ? String(Number(lowRateValue).toFixed(2)) : null}
                      unit={unit}
                      showSquare={true}
                      color={chartPallete.bar.lowRate.fill}
                      componentVariant="ChartTooltip"
                    />
                  </Show>
                  <Show when={(chartAreaTooltip || chartBarTooltip || !chartOrigin) && !energy}>
                    <ConsumptionItem
                      label={t('power_consumption_page.high_rate')}
                      value={highRateValue ? String(Number(highRateValue).toFixed(2)) : null}
                      unit={unit}
                      showSquare={true}
                      color={chartPallete.bar.highRate.fill}
                      componentVariant="ChartTooltip"
                    />
                  </Show>
                  <Show when={!amountForecastIsZero}>
                    <ConsumptionItem
                      label={t('power_consumption_page.forecast')}
                      value={String(Number(forecastValue).toFixed(2))}
                      unit={unit}
                      showSquare={true}
                      color={chartPallete.bar.forecast.fill}
                      border={chartPallete.bar.forecast.stroke}
                      componentVariant="ChartTooltip"
                    />
                  </Show>
                  <Show
                    when={
                      !!energy &&
                      ((maxPower && maxPower.timestamp !== currentPayload[0].payload.timestampStartOfPeriod) ||
                        !maxPower)
                    }
                  >
                    <ConsumptionItem
                      label={t('consumption.energy')}
                      value={String(Number(energy).toFixed(2))}
                      unit={unit}
                      showSquare={true}
                      color={chartPallete.bar.highRate.fill}
                      componentVariant="ChartTooltip"
                    />
                  </Show>
                  <Show when={!!maxPower && maxPower.timestamp === currentPayload[0].payload.timestampStartOfPeriod}>
                    <ConsumptionItem
                      label={t('consumption.maxPower')}
                      value={maxPower?.maxPower ? String(Number(maxPower.maxPower).toFixed(2)) : null}
                      unit={unit}
                      showSquare={true}
                      maxPower
                      color={chartPallete.maxPower.icon}
                      componentVariant="ChartTooltip"
                    />
                  </Show>
                </Box>
              </Show>
              <Show when={isProsumer}>
                <Box>
                  <Show when={chartAreaTooltip || chartBarTooltip || !chartOrigin}>
                    <ProsumerItem
                      label={t('prosumer.prosumer_feed_in')}
                      value={feedIn !== undefined ? String(Number(feedIn).toFixed(2)) : null}
                      unit={unit}
                      showSquare={true}
                      color={prosumerPalette.prosumerFeedIn}
                      componentVariant="ChartTooltip"
                    />
                  </Show>
                  <Show when={chartAreaTooltip || chartBarTooltip || !chartOrigin}>
                    <ProsumerItem
                      label={t('prosumer.prosumer_consumption')}
                      value={consumption !== undefined ? String(Number(consumption).toFixed(2)) : null}
                      unit={unit}
                      showSquare={true}
                      color={prosumerPalette.prosumerConsumption}
                      componentVariant="ChartTooltip"
                    />
                  </Show>
                </Box>
              </Show>
            </Show>
            <Show when={chartOrigin === 'ComparationChart'}>
              <Box>
                <Show when={comparisonChartTooltip && !comparisonForecastConsumptionIsZero}>
                  <ConsumptionItem
                    label={t('power_consumption_page.past_consumption')}
                    value={String(Number(comparisonPastConsumption).toFixed(2))}
                    unit={unit}
                    showSquare={true}
                    color={comparisonPalette.forecast.past}
                    componentVariant="ChartTooltip"
                  />
                </Show>
                <Show when={comparisonChartTooltip && !comparisonForecastConsumptionIsZero}>
                  <ConsumptionItem
                    label={t('power_consumption_page.forecast')}
                    value={String(Number(comparisonForecastConsumption).toFixed(2))}
                    unit={unit}
                    showSquare={true}
                    color={comparisonPalette.forecast.estimated}
                    componentVariant="ChartTooltip"
                  />
                </Show>
                <Show
                  when={
                    comparisonChartTooltip &&
                    !isProsumer &&
                    (comparisonCurrentConsumptionIsZero || comparisonForecastConsumptionIsZero)
                  }
                >
                  <ConsumptionItem
                    label={t('power_consumption_page.consumption')}
                    value={String(Number(comparisonCurrentConsumption).toFixed(2))}
                    unit={unit}
                    showSquare={true}
                    color={comparisonPalette.consumption.fill}
                    componentVariant="ChartTooltip"
                  />
                </Show>
                <Show when={isProsumer && activeBar === 'feedIn'}>
                  <ProsumerItem
                    label={t('prosumer.prosumer_feed_in')}
                    value={feedIn !== undefined ? String(Number(feedIn).toFixed(2)) : null}
                    unit={unit}
                    showSquare={true}
                    color={prosumerPalette.prosumerFeedIn}
                    componentVariant="ChartTooltip"
                  />
                </Show>
                <Show when={isProsumer && activeBar === 'balance'}>
                  <ProsumerItem
                    label={t('prosumer.prosumer_balance')}
                    value={balance !== undefined ? String(Number(balance).toFixed(2)) : null}
                    unit={unit}
                    showSquare={true}
                    color={
                      Number(balance) < 0 ? prosumerPalette.prosumerFeedInNegative : comparisonPalette.consumption.fill
                    }
                    componentVariant="ChartTooltip"
                  />
                </Show>
              </Box>
            </Show>
          </Box>
          <Show when={!!currentPayload[0].payload.description && chartAreaTooltip}>{tooltipDescription}</Show>
          <Show when={!chartOrigin || chartBarTooltip || comparisonChartTooltip}>
            <Box>
              <Show
                when={(!chartOrigin || chartBarTooltip || (comparisonChartTooltip && !amountForecastIsZero)) && !energy}
              >
                <Show when={noDataValueInBarChart}>
                  <Divider sx={{ mt: 1, mb: isConsumer ? 1 : 0, visibility: isProsumer ? 'hidden' : 'auto' }} />
                  {/* TODO: this is removed based on the story dtp-819 leaved if it needs to bring back */}
                  {/* <Show when={isProsumer}>
                    <ConsumptionItem
                      label={t('prosumer.prosumer_balance')}q
                      value={String(Number(prosumerBalance).toFixed(2))}
                      unit={unit}
                      showSquare={false}
                      componentVariant="ChartTooltip"
                    />
                  </Show> */}
                  <Show when={!isProsumer && amountForecastIsZero}>
                    <ConsumptionItem
                      label={t('power_consumption_page.total_consumption')}
                      value={String(Number(sumOfRatesValue).toFixed(2))}
                      unit={unit}
                      showSquare={false}
                      componentVariant="ChartTooltip"
                    />
                  </Show>
                  <Show when={!amountForecastIsZero}>
                    <ConsumptionItem
                      label={t('power_consumption_page.estimated_consumption')}
                      value={String(Number(comparisonEstimatedConsumption).toFixed(2))}
                      unit={unit}
                      showSquare={false}
                      componentVariant="ChartTooltip"
                    />
                  </Show>
                </Show>
              </Show>
              <Show when={!!currentPayload[0].payload.description}>{tooltipDescription}</Show>
              <Show when={noDataValueInBarChart}>
                <CustomButton
                  onClick={() => {
                    handleOnDateChange?.(currentPayload[0].payload.timestampStartOfPeriod)
                    setTooltipOpen(false)
                  }}
                  variant={'text'}
                  endIcon={<ArrowForwardIcon />}
                >
                  {chartType === chartAxisEnum.YEAR || aggregationType === 'year'
                    ? t('power_consumption_page.go_to_yearly_view')
                    : translateForMonthAndDay}
                </CustomButton>
              </Show>
            </Box>
          </Show>
        </CustomBox>
      </Box>
    )
  }

  return null
}
