import { CheckIcon } from '@assets/icons'
import { CardItem } from '@components/DtpInvoiceCard/CardItem'
import { DtpInvoiceCardWrapper } from '@components/DtpInvoiceCard/DtpInvoiceCard'
import { invoiceStatus } from '@enums/invoiceStatus'
import { getFormattedBillingPeriod } from '@helpers/invoices'
import { Box, Stack, SxProps, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import InvoiceStatus from './InvoiceStatus'

export interface IDtpInvoiceCardProps {
  invoiceId: string
  dueDate: string
  invoiceStatus: invoiceStatus
  eBill: boolean
  billingPeriodFromDate: string
  billingPeriodToDate: string
  sx?: SxProps
}

const DtpInvoiceDetailCard = ({
  invoiceId,
  dueDate,
  eBill,
  invoiceStatus,
  billingPeriodFromDate,
  billingPeriodToDate,
  sx,
}: IDtpInvoiceCardProps) => {
  const { t } = useTranslation()

  const billingPeriod = getFormattedBillingPeriod(billingPeriodFromDate, billingPeriodToDate, true)

  return (
    <DtpInvoiceCardWrapper
      role="open-invoice-card"
      sx={{
        cursor: 'default',
        '&:hover': {
          boxShadow: 'none',
        },
        ...sx,
      }}
    >
      <CardItem title={t('invoices.invoice_time_period')} value={billingPeriod} />
      <CardItem title={t('invoices.invoice_nr')} value={invoiceId} />
      <CardItem title={t('invoices.invoice_due_date')} value={dayjs(dueDate).format('DD.MM.YYYY')} />
      <InvoiceStatus title={t('invoices.invoice_status')} invoiceStatus={invoiceStatus} />
      <Show when={eBill}>
        <Stack flexDirection="row" alignItems="center" mt={2}>
          <Box mt={0.5}>
            <CheckIcon color="success" fontSize="small" />
          </Box>
          <Typography variant="bodyRegular" sx={{ my: 0, ml: 1 }}>
            {t('invoices.invoice_ebill')}
          </Typography>
        </Stack>
      </Show>
    </DtpInvoiceCardWrapper>
  )
}

export default DtpInvoiceDetailCard
