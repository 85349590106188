import theme from '@assets/themes/theme'
import DtpAccountCard from '@components/DtpAccountCard/DtpAccountCard'
import DtpErrorModal from '@components/DtpErrorModal/DtpErrorModal'
import DtpModal from '@components/DtpModal/DtpModal'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { IAccount } from '@interfaces/accounts'
import { Container, Typography, Grid, Stack, Button, CircularProgress } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { Trans } from 'react-i18next'

const ManagedAccounts = () => {
  const {
    accounts,
    activeAccount,
    switchAccount,
    t,
    handleSetMainAccount,
    handleRemoveAccount,
    isLoading,
    isDeleting,
    mainAccount,
    standardAccounts,
    setOpenDeleteModal,
    setOpen,
    open,
    openDeleteModal,
    selectedGpnr,
    handleRemoveMainAccount,
  } = useAccounts()

  return (
    <>
      <Container>
        <Typography variant="heading3" sx={{ mt: { xs: 1.5, md: 9 }, mb: 4 }}>
          {t('navigation.navi_managed_accounts')}
        </Typography>

        <Show when={!!mainAccount.length}>
          <Typography variant="leadEmphasized" sx={{ marginBottom: 5 }}>
            {t('accounts.main_account')}
          </Typography>
          {mainAccount.map((account: IAccount) => (
            <DtpAccountCard
              key={account.gpnr}
              {...account}
              active={account.gpnr === activeAccount.gpnr}
              onChangeAccount={() => switchAccount(account)}
              onDeleteAccount={() => handleRemoveMainAccount()}
            />
          ))}
        </Show>

        <Show when={!!standardAccounts.length}>
          <Typography variant="leadEmphasized" sx={{ marginBottom: 0, marginTop: 5 }}>
            {t('accounts.additional_accounts')}
          </Typography>
          <Grid container columnGap={3} mb={2}>
            {standardAccounts.map((account: IAccount) => (
              <Grid key={account.gpnr} item xs={12} md="auto">
                <DtpAccountCard
                  {...account}
                  active={account.gpnr === activeAccount.gpnr}
                  onChangeAccount={() => switchAccount(account)}
                  onSetMainAccount={() => handleSetMainAccount(account.gpnr)}
                  onDeleteAccount={() => {
                    account.gpnr === activeAccount.gpnr ? setOpenDeleteModal(true) : handleRemoveAccount(account.gpnr)
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Show>
      </Container>
      <DtpErrorModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => handleSetMainAccount(selectedGpnr)}
        isLoading={isLoading}
      />

      <DtpModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t('accounts.are_you_sure')}
        titleTypographyVariant="heading3"
        sx={{
          '.MuiPaper-root': {
            padding: { xs: theme.spacing(3, 4), sm: theme.spacing(6, 8) },
            gap: {
              xs: 0,
              sm: 4,
            },
            width: '100%',
            maxWidth: 784,
            [theme.breakpoints.down('sm')]: {
              maxHeight: 'none',
              height: '100%',
              margin: 0,
            },
          },
        }}
      >
        <Stack justifyContent={{ xs: 'space-between', sm: 'auto' }} height={{ xs: '100%', sm: 'auto' }} gap={4}>
          <Typography variant="bodyRegular">
            {accounts.length > 1 ? (
              <Trans
                i18nKey="accounts.remove_account_explanation"
                values={{
                  contractName: activeAccount.name,
                }}
              />
            ) : (
              <Trans
                i18nKey="accounts.delete_main_account_explanation"
                components={{
                  strong: <Typography component="span" variant="bodyRegularEmphasized" fontWeight="700" />,
                }}
              />
            )}
          </Typography>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleRemoveAccount(activeAccount.gpnr)}
              disabled={isDeleting}
              endIcon={isDeleting && <CircularProgress />}
            >
              {t('accounts.confirm_remove_account')}
            </Button>
            <Button variant="outlined" onClick={() => setOpenDeleteModal(false)}>
              {t('cancel')}
            </Button>
          </Stack>
        </Stack>
      </DtpModal>
    </>
  )
}

export default ManagedAccounts
