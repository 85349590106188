import {
  invoiceEmailConfirmation,
  invoiceEmailInitiation,
  authorizeUserAccount,
  requestAccessCodeMethod,
} from '@api/mutations'
import { getAuthOptions, getAutomaticAuth } from '@api/queries'
import { removeBlankSpaces } from '@helpers/functional'
import { useAuth } from '@hooks/useAuth/useAuth'
import useNotification from '@hooks/useNotification/useNotification'
import { authorizeUserSchema } from '@src/validations/authorizationValidationSchema'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { useMemo } from 'react'

const useAuthorizeUser = () => {
  const notification = useNotification()
  const { clearToken } = useAuth()

  const authorizeUserForm = useFormik({
    validationSchema: authorizeUserSchema,
    validateOnChange: true,
    initialValues: {
      gpnr: '',
      evidence: '',
    },
    onSubmit: async ({ gpnr, evidence }) =>
      authorizeAccount({ gpnr: removeBlankSpaces(gpnr), evidence: removeBlankSpaces(evidence) }),
  })

  const gpnr = removeBlankSpaces(authorizeUserForm.values.gpnr)

  const { data: authOptionsData, isFetching: isLoadingAuthOptions } = useQuery({
    queryKey: ['auth-options', gpnr],
    queryFn: () => getAuthOptions(gpnr),
    enabled: !!gpnr,
  })

  const { mutateAsync: requestAccessCode } = useMutation({
    mutationKey: ['requestAccessCode'],
    mutationFn: (medium: 'invoiceEmail' | 'mobileNumber') => requestAccessCodeMethod({ gpnr, medium }),
  })

  const { data: automaticAuth, isLoading: isLoadingAutomaticAuth } = useQuery({
    queryKey: ['automaticAuthPossibility'],
    queryFn: getAutomaticAuth,
  })

  const { mutateAsync: emailIntiation } = useMutation({
    mutationKey: ['resendEmailAuth'],
    mutationFn: invoiceEmailInitiation,
  })

  const { mutateAsync: emailConfirmation } = useMutation({
    mutationKey: ['confirmEmail'],
    mutationFn: invoiceEmailConfirmation,
    onSuccess: () => {
      notification('success')
      clearToken()
    },
  })

  const { mutateAsync: authorizeAccount, isLoading: isAuthorizingAccount } = useMutation({
    mutationKey: ['authorizeAccount'],
    mutationFn: authorizeUserAccount,
    onSuccess: (data) => {
      if (data?.body?.isUserAuthorized) {
        notification('success')
        clearToken()
      } else {
        notification('error')
      }
    },
  })

  const canAuthorizeAutomatically = automaticAuth?.body?.authorizationPossible ?? false

  const authOptions = useMemo(() => authOptionsData?.body ?? {}, [authOptionsData])

  return {
    authOptions,
    isLoadingAuthOptions,
    canAuthorizeAutomatically,
    isLoadingAutomaticAuth,
    emailIntiation,
    emailConfirmation,
    authorizeAccount,
    authorizeUserForm,
    isAuthorizingAccount,
    requestAccessCode,
  }
}

export default useAuthorizeUser
