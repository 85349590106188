import BackgroundImage from '@assets/images/ckw-home-backgroud.png'
import { Stack, styled, Container } from '@mui/material'

const BackgroundWrapper = styled(Stack)(() => ({
  background: `url(${BackgroundImage})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 24,
}))

const CardWrapper = styled(Stack)(({ theme }) => ({
  padding: 24,
  maxWidth: 720,
  margin: 'auto',
  backgroundColor: 'white',
  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.borderRadius.large,
  [theme.breakpoints.up('sm')]: {
    padding: 48,
  },
}))

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <BackgroundWrapper>
      <Container>
        <CardWrapper>{children}</CardWrapper>
      </Container>
    </BackgroundWrapper>
  )
}

export default Wrapper
