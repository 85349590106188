import { withErrorLogging } from '@helpers/hof'
import { IAuthOptionsApiResponse, IAutorizationPossibilityApiResponse } from '@interfaces/authorization'
import { IContractsApiResponse } from '@interfaces/contracts'
import { IInvoiceProps, IInvoicesApiResponse, IInvoicesSettingsApiResponse } from '@interfaces/invoices'
import { ILastAvailableDayParams } from '@interfaces/lastAvailableDay'
import {
  IAggregationMeasurementsProps,
  IDownloadAggregationMeasurementsProps,
  IMeasurementResponse,
  IComparisonProps,
} from '@interfaces/measurements'

import { api, apiAccount, apiInvoice, apiAuthorization } from './index'

export const getLastAvailableDay = withErrorLogging(
  async (params: ILastAvailableDayParams): Promise<IMeasurementResponse> => {
    const { gpnr, ...rest } = params
    const { data } = await api.post(
      `/v1/electricityconsumption/lastavailableday/${rest.locationId ? 'location' : 'meter'}`,
      { ...rest },
      { headers: { gpnr } }
    )
    return data
  }
)

export const getAggregationMeasurements = withErrorLogging(
  async (params: IAggregationMeasurementsProps): Promise<IMeasurementResponse> => {
    const { gpnr, ...rest } = params
    const { data } = await api.post(
      `/v1/electricityconsumption/measurements/${rest.locationId ? 'location' : 'meter'}`,
      { ...rest },
      { headers: { gpnr } }
    )
    return data
  }
)
export const getDownloadAggregationMesurements = async (
  params: IDownloadAggregationMeasurementsProps
): Promise<File> => {
  const { gpnr, ...rest } = params
  const { data } = await api.post(
    `v1/file/${rest.locationId ? 'location' : 'meter'}`,
    { ...rest },
    { headers: { gpnr } }
  )
  return data
}

export const getComparisonData = async (params: IComparisonProps) => {
  const { gpnr, ...rest } = params
  const { data } = await api.post(
    `/v1/electricityconsumption/comparison/${rest.locationId ? 'location' : 'meter'}`,
    {
      ...rest,
    },
    { headers: { gpnr } }
  )
  return data
}

export const getAccounts = async () => {
  const { data } = await apiAccount.get('/v1/account')
  return data
}

export const getAccountDetails = async (gpnr: string) => {
  const { data } = await apiAccount.get('/v1/account/details', { headers: { gpnr } })
  return data
}

export const getContracts = async (gpnr: string): Promise<IContractsApiResponse> => {
  const { data } = await apiAccount.get('/v1/account/contracts', { headers: { gpnr } })
  return data
}

export const getInvoices = async (params: IInvoiceProps): Promise<IInvoicesApiResponse> => {
  const { gpnr, ...rest } = params
  const { data } = await apiInvoice.post('/v1/invoice/overview', { ...rest }, { headers: { gpnr } })
  return data
}

export const getInvoiceContent = async ({ invoiceId, gpnr }: { invoiceId: string; gpnr: string }) => {
  const { data } = await apiInvoice.get(`/v1/invoice/${invoiceId}/content`, { headers: { gpnr } })
  return data
}

export const getInvoiceSettings = async ({
  contractAccountId,
  gpnr,
}: {
  contractAccountId?: string
  gpnr: string
}): Promise<IInvoicesSettingsApiResponse> => {
  const { data } = await apiInvoice.get('/v1/invoice/settings', { params: { contractAccountId }, headers: { gpnr } })
  return data
}

export const getAuthOptions = async (gpnr: string): Promise<IAuthOptionsApiResponse> => {
  const { data } = await apiAuthorization.get('/v1/authorization/auth-options', { headers: { gpnr } })
  return data
}

export const getAutomaticAuth = async (): Promise<IAutorizationPossibilityApiResponse> => {
  const { data } = await apiAuthorization.get('/v1/authorization/automatic-authorization-possible')
  return data
}
