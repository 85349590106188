import theme from '@assets/themes/theme'
import DtpSearchBar from '@components/DtpSearchBar/DtpSearchBar'
import { filterLocations } from '@helpers/locationsFunctions'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import { InputBase, MenuItem, Select, SelectProps, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { useState, useMemo, useRef, useCallback } from 'react'

import { ArrowDownIcon } from '../../../assets/icons'
import DtpFormWrapper, { FormProps } from '../DtpFormWrapper/DtpFormWrapper'
import { ActiveMenuItem } from './components/ActiveMenuItem'
import { ActiveMenuSubItem } from './components/ActiveMenuSubItem'
import { ExpiredMenuItem } from './components/ExpiredMenuItem'
import TerminatedContractsCheckbox from './components/TerminatedContractsCheckbox'

export interface ISelectItem<T = IAddOn> {
  label: string
  value: string
  addOn?: T
}
export interface IMenuItemProps {
  item: ISelectItem<IAddOn>
  handleClick: (item: ISelectItem<IAddOn>) => void
}

/**
 * onChange() returns event.target.value representing item value as string
 * onItemSelect() returns entire item object (value, label & custom addOn)
 */

export interface IDtpDropdownProps<T> extends FormProps {
  activeLocations: ISelectItem<IAddOn>[]
  expiredLocations: ISelectItem<IAddOn>[]
  showExpiredContracts?: boolean
  placeholder?: string
  noDataLabel?: string
  isLoading?: boolean
  onLocationsSelect?: (item: ISelectItem<T | IAddOn>) => void
  handleChangeExpireContracts?: () => void
}

export function DtpDropdown<T>(props: IDtpDropdownProps<T> & SelectProps<string>) {
  const {
    activeLocations = [],
    expiredLocations = [],
    value,
    placeholder,
    noDataLabel,
    helperText,
    required,
    label,
    error,
    fullWidth,
    onLocationsSelect,
    showExpiredContracts,
    handleChangeExpireContracts,
    sx,
    isLoading,
    ...rest
  } = props
  const [searchTerm, setSearchTerm] = useState('')

  const searchInputRef = useRef<HTMLInputElement>(null)

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClick = (item: ISelectItem<T | IAddOn>) => onLocationsSelect?.(item)

  const handleClearSearch = useCallback(() => {
    setSearchTerm('')
    if (searchInputRef.current) {
      searchInputRef.current.value = ''
    }
  }, [])

  const hasSameParentLocation = useMemo(() => {
    if (!expiredLocations.length) {
      return false
    }

    return expiredLocations.every((item) => item.addOn?.parentLocation === expiredLocations[0].addOn?.parentLocation)
  }, [expiredLocations])

  const filteredActiveLocations = useMemo(() => {
    return filterLocations(activeLocations, searchTerm, value)
  }, [activeLocations, searchTerm, value])

  const filteredExpiredLocations = useMemo(() => {
    return filterLocations(expiredLocations, searchTerm, value)
  }, [expiredLocations, searchTerm, value])

  const hasNoOptions = !filteredActiveLocations.length && !filteredExpiredLocations.length

  const shouldRenderSearch = activeLocations.length + expiredLocations.length > 7

  if (isLoading) return <Skeleton sx={{ width: 200, height: 45 }} />

  return (
    <DtpFormWrapper label={label} helperText={helperText} error={error} fullWidth={fullWidth} required={required}>
      <Select
        displayEmpty
        value={filteredActiveLocations?.length || filteredExpiredLocations?.length ? value : ''}
        variant={'standard'}
        input={<InputBase error={error} placeholder={placeholder} />}
        IconComponent={ArrowDownIcon}
        onClose={handleClearSearch}
        MenuProps={{
          slotProps: {
            paper: {
              sx: {
                '.MuiList-root': {
                  listStyle: 'disc',
                  width: isMobile ? '100%' : 340,
                },
              },
            },
          },
        }}
        sx={{
          width: isMobile ? '100%' : 340,
          p: 0,
          '.MuiSelect-select svg': {
            display: 'none',
          },
          ...sx,
        }}
        id="dropdownMenu"
        {...rest}
      >
        {shouldRenderSearch && (
          <DtpSearchBar
            ref={searchInputRef}
            showClearIcon={!!searchTerm}
            value={searchTerm}
            onChange={({ target }) => setSearchTerm(target.value)}
            onClearSearch={handleClearSearch}
          />
        )}

        {placeholder && (
          <MenuItem disabled value="" sx={{ display: 'none' }}>
            {placeholder}
          </MenuItem>
        )}
        {hasNoOptions && (
          <MenuItem disabled value="">
            {noDataLabel}
          </MenuItem>
        )}

        {filteredActiveLocations?.map((item) => {
          if (item.addOn?.locationId) {
            return ActiveMenuItem({ item, handleClick })
          } else if (item.addOn?.contractId && item.addOn?.hasMoreThanOneContract) {
            return ActiveMenuSubItem({ item, handleClick })
          }
        })}

        {activeLocations.length && expiredLocations?.length && (
          <TerminatedContractsCheckbox checked={showExpiredContracts} onChange={handleChangeExpireContracts} />
        )}

        {hasSameParentLocation && showExpiredContracts && (
          <Typography variant="caption" sx={{ px: 2 }}>
            {expiredLocations && expiredLocations[0]?.addOn?.parentLocation}
          </Typography>
        )}

        {showExpiredContracts &&
          filteredExpiredLocations?.map((item) => {
            if (item.addOn?.contractId) {
              return [
                !hasSameParentLocation && (
                  <Typography variant="caption" sx={{ px: 2 }}>
                    {item.addOn?.parentLocation}
                  </Typography>
                ),
                ExpiredMenuItem({ item, handleClick }),
              ]
            }
          })}
      </Select>
    </DtpFormWrapper>
  )
}
