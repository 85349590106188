import { PhoneIcon } from '@assets/icons'
import Wrapper from '@components/DtpConnectAccountWizard/common/Wrapper'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'

import MethodSelectCard from '../../common/MethodSelectCard'

const ContactSupport = ({ goBack, accountNumber }: { goBack: () => void; accountNumber?: string }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Box>
        <Typography variant="heading3" sx={{ mt: 0, mb: 2 }}>
          {t('authorization.no_contact_details_available')}
        </Typography>
        <Typography variant="bodyRegular">
          <Trans
            i18nKey="authorization.no_self_service_for_customer_number_explanation"
            values={{
              workTime: t('authorization.opening_hours_contact_center'),
            }}
            components={{
              strong: <Typography component="span" variant="bodyRegularEmphasized" fontWeight="700" />,
            }}
          />
        </Typography>
        <MethodSelectCard
          descriptionColor="primary"
          icon={<PhoneIcon />}
          title={t('authorization.call_us')}
          description={'0800 88 77 66'}
        />
        <Box mb={4}>
          <Typography variant="bodyRegular" sx={{ my: 0.5 }}>
            <Trans
              i18nKey="authorization.prepare_customer_number"
              values={{
                customerNumber: t('authorization.auth_customer_number'),
              }}
              components={{
                strong: <Typography component="span" variant="bodyRegularEmphasized" fontWeight="700" />,
              }}
            />
          </Typography>
          <Typography variant="bodyRegularEmphasized" fontWeight="500" sx={{ my: 0 }}>
            {accountNumber}
          </Typography>
        </Box>
        <Button variant="outlined" size="large" onClick={goBack}>
          {t('button_close')}
        </Button>
      </Box>
    </Wrapper>
  )
}

export default ContactSupport
