import { AlertTriangleIcon, CheckFilledIcon, InfoStatusIcon } from '@assets/icons'
import { toastPalette } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  Alert,
  AlertProps,
  AlertTitle,
  Snackbar,
  SnackbarCloseReason,
  SnackbarProps,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const DtpToast = (props: SnackbarProps & AlertProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(true)
  const { t } = useTranslation()
  const { title, message, severity, anchorOrigin, autoHideDuration } = props

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const returnTitle = () => {
    if (!title) {
      switch (severity) {
        case 'error':
          return t('toast.error_default_title')
        case 'info':
          return t('toast.info_default_title')
        case 'warning':
          return t('toast.warning_default_title')
        case 'success':
          return t('toast.success_default_title')
        default:
          return ''
      }
    } else return title
  }

  const returnMessage = () => {
    if (!message) {
      switch (severity) {
        case 'error':
          return t('toast.error_default_explanation')
        case 'info':
          return t('toast.info_default_explanation')
        case 'warning':
          return t('toast.warning_default_explanation')
        case 'success':
          return t('toast.success_default_explanation')
        default:
          return ''
      }
    } else return message
  }

  return (
    <Snackbar
      sx={{
        width: isMobile ? 'inherit' : '400px',
        minHeight: '32px',
        '&.MuiSnackbar-anchorOriginTopRight': { top: '88px' },
      }}
      anchorOrigin={anchorOrigin ?? { vertical: isMobile ? 'bottom' : 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      key={severity}
    >
      <Alert
        sx={{
          width: 1,
          border: '1px solid',
          '&.MuiAlert-colorInfo': {
            backgroundColor: toastPalette.info.background,
            borderColor: toastPalette.info.border,
            color: toastPalette.info.main,
          },
          '&.MuiAlert-colorInfo .MuiAlertTitle-root': {
            color: toastPalette.info.main,
          },
          '&.MuiAlert-colorWarning': {
            backgroundColor: toastPalette.warning.background,
            borderColor: toastPalette.warning.border,
            color: toastPalette.warning.main,
          },
          '&.MuiAlert-colorWarning .MuiAlertTitle-root': {
            color: toastPalette.warning.main,
          },
          '&.MuiAlert-colorSuccess': {
            backgroundColor: toastPalette.success.background,
            borderColor: toastPalette.success.border,
            color: toastPalette.success.main,
          },
          '&.MuiAlert-colorSuccess .MuiAlertTitle-root': {
            color: toastPalette.success.main,
          },
          '&.MuiAlert-colorError': {
            backgroundColor: toastPalette.error.background,
            borderColor: toastPalette.error.border,
            color: toastPalette.error.main,
          },
          '&.MuiAlert-colorError .MuiAlertTitle-root': {
            color: toastPalette.error.main,
          },
        }}
        severity={severity}
        onClose={handleClose}
        iconMapping={{
          success: <CheckFilledIcon sx={{ color: toastPalette.success.main }} />,
          info: <InfoStatusIcon sx={{ color: toastPalette.info.main }} />,
          warning: <AlertTriangleIcon sx={{ path: { fill: toastPalette.warning.main } }} />,
          error: <CancelIcon sx={{ color: toastPalette.error.main }} />,
        }}
      >
        <AlertTitle sx={{ margin: 0 }}>{returnTitle()}</AlertTitle>

        <Typography variant="caption" marginBottom={0}>
          {returnMessage()}
        </Typography>
      </Alert>
    </Snackbar>
  )
}

export default DtpToast
