import theme from '@assets/themes/theme'

interface IChartLabel {
  dy?: number | string
  value?: number | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewBox?: any
  color?: string
  borderColor?: string
  font?: string
}

const ChartLabel = ({ dy, value, viewBox: { x, y, width }, color, borderColor, font }: IChartLabel) => {
  const rectHeight = 29
  const transform = `translate(${x + Number(dy)} ${y - rectHeight / 2})`
  const isMobile = width <= 600
  const textLength = String(value).length
  const rectDefaultWidth = textLength > 18 ? 90 : 64
  const approximateAvgCharWidth = textLength > 18 ? 6 : 9

  const primaryColor = color ?? theme.palette.text.secondary
  const strokeColor = borderColor ?? theme.palette.background.default
  const backgroundColor = theme.palette.background.paper

  const fontFamily = font ?? (isMobile ? 'Montserrat' : 'GothamMedium')
  const fontWeight = isMobile ? 'bold' : '500'
  const fontSize = isMobile ? 12 : 14
  const opacity = isMobile ? 0.95 : 1

  const rectWidth = Math.max(textLength * approximateAvgCharWidth + 30, rectDefaultWidth)

  return (
    <g transform={transform}>
      <rect
        width={rectWidth}
        height={rectHeight}
        rx={8}
        fill={backgroundColor}
        stroke={strokeColor}
        strokeWidth={1}
        opacity={opacity}
        style={{ filter: 'drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1))' }}
      />
      <text
        x={rectWidth / 2}
        y={rectHeight / 2}
        fill={primaryColor}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={fontSize}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
      >
        <tspan>{value}</tspan>
      </text>
    </g>
  )
}

export default ChartLabel
