import { IInvoice, IInvoiceBody } from '@interfaces/invoices'
import dayjs from 'dayjs'

export const getFormattedBillingPeriod = (
  billingPeriodFromDate?: string | null,
  billingPeriodToDate?: string | null,
  noBrackets?: boolean
): string => {
  const formatDate = (date?: string | null) => {
    return date ? dayjs(date).format('DD.MM.YY') : '?'
  }

  if (!billingPeriodFromDate && !billingPeriodToDate) {
    return '( - )'
  }

  const fromDate = formatDate(billingPeriodFromDate)
  const toDate = formatDate(billingPeriodToDate)

  if (noBrackets) {
    return `${fromDate} - ${toDate}`
  }

  return `(${fromDate} - ${toDate})`
}

export const getNewestInvoice = (data: IInvoiceBody): IInvoice | null => {
  const allInvoices = Object.values(data).flat()

  const latestInvoice = allInvoices.reduce<IInvoice | null>((latest, current) => {
    const currentInvoiceDate = dayjs(current.invoiceDate)
    if (!latest || currentInvoiceDate.isAfter(dayjs(latest.invoiceDate))) {
      return current
    }
    return latest
  }, null)

  return latestInvoice
}
