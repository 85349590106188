import { useFeatureToggle } from '@hooks/useFeatureToggle/useFeatureToggle'
import React from 'react'

interface IFeatureProps {
  flag: string
  children: React.ReactNode
}

const FeatureToggle = ({ flag, children }: IFeatureProps) => {
  const { features } = useFeatureToggle()
  const isEnabled = features[flag]
  return isEnabled ? <>{children}</> : null
}

export default FeatureToggle
