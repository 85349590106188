import TooltipLabel from '@components/DtpConnectAccountWizard/common/TooltipLabel'
import Wrapper from '@components/DtpConnectAccountWizard/common/Wrapper'
import DtpTextField from '@components/DtpTextField/DtpTextField'
import { IEmailConfirmation } from '@interfaces/authorization'
import { Box, Button, Typography } from '@mui/material'
import { useMask } from '@react-input/mask'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export interface IOneTimePasswordProps {
  otp: string
  emailConfirmation: (data: IEmailConfirmation) => void
}

const OneTimePassword = ({ otp, emailConfirmation }: IOneTimePasswordProps) => {
  const { t } = useTranslation()

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      otp,
    },
    onSubmit: emailConfirmation,
  })

  const otpInputRef = useMask({ mask: '___-___-___-___', replacement: '_' })

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Box mb={4}>
          <Typography variant="heading3">{t('authorization.enter_confirmation_code')}</Typography>
          <Typography variant="bodyRegular">{t('authorization.enter_confirmation_code_manually')}</Typography>
        </Box>
        <DtpTextField
          value={values.otp}
          onChange={handleChange}
          name="otp"
          inputRef={otpInputRef}
          label={
            <TooltipLabel
              label={t('authorization.confirmation_code')}
              tooltipLabel={t('authorization.tooltip_access_code')}
            />
          }
          inputMode="tel"
          helperText={touched.otp ? errors.otp : undefined}
          error={touched.otp && Boolean(errors.otp)}
          placeholder="xxx xxx xxx"
          fullWidth
        />
        <Box mt={4}>
          <Button type="submit">{t('authorization.complete')}</Button>
        </Box>
      </form>
    </Wrapper>
  )
}

export default OneTimePassword
