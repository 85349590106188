import { SuspenseLoading } from '@components/SuspenseLoading/SuspenseLoading'
import { Wizard } from '@components/Wizard/Wizard'
import useAuthorizeUser from '@hooks/useAuthorizeUser/useAuthorizeUser'
import { useState } from 'react'

import AccessCode from './steps/AccessCode/AccessCode'
import AccountNumber from './steps/AccountNumber/AccountNumber'
import ContactSupport from './steps/ContactSupport/ContactSupport'
import OneTimePassword from './steps/OneTimePassword/OneTimePassword'
import RequestNewCode from './steps/RequestNewCode/RequestNewCode'
import ResendEmail from './steps/ResendEmail/ResendEmail'

export enum FormSteps {
  ACCOUNT_NUMBER_STEP = 'account-number',
  ACCESS_CODE_STEP = 'access-code',
  REQUEST_ACCESS_CODE = 'request-access-code',
  SUPPORT = 'contact-support',
  AUTOMATIC_AUTH = 'automatic-authorization',
  AUTOMATIC_AUTH_OTP = 'automatic-authorization-otp',
}

const DtpConnectAccountWizard = () => {
  const [otp, setOtp] = useState('')

  const {
    authOptions,
    isLoadingAuthOptions,
    canAuthorizeAutomatically,
    isLoadingAutomaticAuth,
    emailIntiation,
    emailConfirmation,
    authorizeUserForm,
    isAuthorizingAccount,
    requestAccessCode,
  } = useAuthorizeUser()

  if (isLoadingAutomaticAuth) return <SuspenseLoading />

  return (
    <Wizard currentStep={canAuthorizeAutomatically ? 4 : 0}>
      <Wizard.Step
        id={FormSteps.ACCOUNT_NUMBER_STEP}
        component={AccountNumber}
        onChange={authorizeUserForm.handleChange}
        onBlur={authorizeUserForm.handleBlur}
        gpnr={authorizeUserForm.values.gpnr}
        error={authorizeUserForm.errors.gpnr}
        touched={authorizeUserForm.touched.gpnr}
      />
      <Wizard.Step
        id={FormSteps.ACCESS_CODE_STEP}
        component={AccessCode}
        onChange={authorizeUserForm.handleChange}
        onBlur={authorizeUserForm.handleBlur}
        onSubmit={authorizeUserForm.handleSubmit}
        evidence={authorizeUserForm.values.evidence}
        error={authorizeUserForm.errors.evidence}
        touched={authorizeUserForm.touched.evidence}
        isLoading={isAuthorizingAccount}
      />
      <Wizard.Step
        id={FormSteps.REQUEST_ACCESS_CODE}
        component={RequestNewCode}
        isLoading={isLoadingAuthOptions}
        authOptions={authOptions}
        requestAccessCode={requestAccessCode}
      />
      <Wizard.Step id={FormSteps.SUPPORT} component={ContactSupport} accountNumber={authorizeUserForm.values.gpnr} />

      <Wizard.Step
        id={FormSteps.AUTOMATIC_AUTH}
        component={ResendEmail}
        emailInitiation={emailIntiation}
        setOtp={setOtp}
      />
      <Wizard.Step
        id={FormSteps.AUTOMATIC_AUTH_OTP}
        component={OneTimePassword}
        otp={otp}
        emailConfirmation={emailConfirmation}
      />
    </Wizard>
  )
}

export default DtpConnectAccountWizard
