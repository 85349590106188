import DtpConnectAccountWizzard from '@components/DtpConnectAccountWizard/DtpConnectAccountWizard'
import DtpFrequentRequests from '@components/DtpFrequentRequests/DtpFrequentRequests'
import DtpHeroCard from '@components/DtpHeroCard/DtpHeroCard'
import DtpInvoiceCardGroup from '@components/DtpInvoiceCardGroup/DtpInvoiceCardGroup'
import DtpNoOpenInvoices from '@components/DtpNoOpenInvoices/DtpNoOpenInvoices'
import DtpInvoiceCardGroupSkeleton from '@components/Skeletons/DtpInvoiceCardGroupSkeleton'
import { useInvoices } from '@hooks/useInvoices/useInvoices'
import useRoleAccess from '@hooks/useRoleAccess/useRoleAccess'
import { Container } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

const Home = () => {
  const { invoices, isLoading } = useInvoices({ status: 'OPEN' })
  const openInvoices = Object.values(invoices).flat()
  const { isEndUser } = useRoleAccess()

  if (!isEndUser) return <DtpConnectAccountWizzard />
  return (
    <>
      <DtpHeroCard />
      <Container>
        <Show when={!isLoading}>
          <DtpInvoiceCardGroup invoices={openInvoices} />
        </Show>
        <Show when={isLoading}>
          <DtpInvoiceCardGroupSkeleton />
        </Show>
        <Show when={!openInvoices.length && !isLoading}>
          <DtpNoOpenInvoices />
        </Show>
        <DtpFrequentRequests />
      </Container>
    </>
  )
}

export default Home
