import { deleteAccount, setMainAccount } from '@api/mutations'
import { getAccounts } from '@api/queries'
import useNotification from '@hooks/useNotification/useNotification'
import { IAccount, IAccountsResponse } from '@interfaces/accounts'
import { useAccountStore } from '@src/stores/accounts/useAccountStore'
import { useLocationStore } from '@src/stores/locations/useLocationStore'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const useAccounts = () => {
  const [open, setOpen] = useState(false)
  const [selectedGpnr, setSelectedGpnr] = useState('')
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

  const { t } = useTranslation()
  const notification = useNotification()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const accounts = useAccountStore((state) => state.accounts)
  const setAccounts = useAccountStore((state) => state.setAccounts)

  const activeAccount = useAccountStore((state) => state.activeAccount)
  const setActiveAccount = useAccountStore((state) => state.setActiveAccount)

  const resetLocations = useLocationStore((state) => state.resetLocations)
  const resetActiveAccount = useAccountStore((state) => state.resetActiveAccount)

  const {
    isLoading,
    refetch,
    isError: isServerError,
  } = useQuery({
    queryKey: ['accounts'],
    queryFn: () => getAccounts(),
    enabled: false,
    onSuccess: ({ body }: IAccountsResponse) => {
      if (!body) return
      const setMappedAccounts = (accounts: IAccount[]) => {
        setAccounts(accounts)
        const activeAccount = accounts.find((account) => account.active)
        activeAccount && setActiveAccount(activeAccount)
      }

      const hasActiveAccount = body.accounts.some((account) => account.mainAccount)

      if (hasActiveAccount) {
        const mappedAccounts = body.accounts.map((account) => {
          return {
            ...account,
            active: activeAccount.gpnr ? activeAccount.gpnr === account.gpnr : !!account.mainAccount,
          }
        })
        setMappedAccounts(mappedAccounts)
      } else {
        body.accounts[0].active = true
        setMappedAccounts(body.accounts)
      }
    },
  })

  const refetchAccounts = useCallback(async () => {
    if (!queryClient.getQueryData(['accounts'])) {
      await refetch()
    }
  }, [queryClient, refetch])

  const { mutateAsync, isLoading: isLoadingSetMainAccount } = useMutation({
    mutationFn: setMainAccount,
    onSuccess: async () => {
      await refetch()
    },
  })

  const { mutateAsync: removeAccount, isLoading: isDeleting } = useMutation({
    mutationFn: deleteAccount,
  })

  const switchAccount = (item: IAccount) => {
    const updatedItems = accounts.map((account) => ({
      ...account,
      active: account.gpnr === activeAccount.gpnr,
    }))

    setAccounts(updatedItems)
    setActiveAccount(item)
    resetLocations()
    navigate('/')
  }

  const handleRemoveAccount = async (gpnr: string) => {
    try {
      await removeAccount(
        { gpnr },
        {
          onSuccess: () => {
            if (gpnr === mainAccount[0].gpnr) {
              return navigate('/')
            } else {
              if (gpnr === activeAccount.gpnr) {
                switchAccount(mainAccount[0])
              }
              setOpenDeleteModal(false)
              notification('success', t('toast.account_removed_title'), t('toast.account_removed_description'))
            }
          },
        }
      )
    } catch (error) {
      setSelectedGpnr(gpnr)
      notification('error')
    }
  }

  const handleRemoveMainAccount = () => {
    accounts.length === 1
      ? setOpenDeleteModal(true)
      : notification('error', t('accounts.removal_not_possible'), t('accounts.select_other_main_account_first'))
  }

  const handleSetMainAccount = async (gpnr: string) => {
    try {
      await mutateAsync({ gpnr })
      setOpen(false)
      notification('success', t('toast.main_account_updated_title'), t('toast.main_account_updated_description'))
    } catch (error) {
      setSelectedGpnr(gpnr)
      setOpen(true)
      notification('error')
    }
  }

  const [mainAccount, standardAccounts] = useMemo(() => {
    return accounts.reduce<[IAccount[], IAccount[]]>(
      (acc, account) => {
        account.mainAccount ? acc[0].push(account) : acc[1].push(account)
        return acc
      },
      [[], []]
    )
  }, [accounts])

  useEffect(() => {
    refetchAccounts()
  }, [refetchAccounts])

  return {
    accounts,
    activeAccount,
    switchAccount,
    isLoading,
    refetch,
    isServerError,
    refetchAccounts,
    setActiveAccount,
    resetActiveAccount,
    open,
    setOpen,
    selectedGpnr,
    setSelectedGpnr,
    openDeleteModal,
    setOpenDeleteModal,
    handleRemoveAccount,
    handleSetMainAccount,
    mainAccount,
    standardAccounts,
    isLoadingSetMainAccount,
    isDeleting,
    t,
    notification,
    handleRemoveMainAccount,
  }
}

export default useAccounts
