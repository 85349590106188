import { chartPallete } from '@assets/themes/palette'
import theme from '@assets/themes/theme'

interface IMaxPowerDot {
  r?: number
  cx?: number
  cy?: number
  strokeWidth?: number
}

const MaxPowerDot = ({ r, cx, cy, strokeWidth }: IMaxPowerDot) => {
  r = r ?? 6
  cx = cx ?? 0
  cy = cy ?? 0
  strokeWidth = strokeWidth ?? 1
  const transform = `translate(${cx - r} ${cy - r})`

  const primaryColor = chartPallete.maxPower.icon
  const backgroundColor = theme.palette.background.paper

  return (
    <g transform={transform}>
      <circle cx={r} cy={r} r={r} fill={backgroundColor} stroke={primaryColor} strokeWidth={strokeWidth} />
      <circle cx={r} cy={r} r={r - strokeWidth * 2} fill={primaryColor} />
    </g>
  )
}

export default MaxPowerDot
