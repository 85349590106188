import BackgroundImage from '@assets/images/ckw-home-backgroud.png'
import { Container, Box, styled } from '@mui/material'
import React from 'react'

interface IHeroContainerProps {
  children: React.ReactNode
}

const HeroWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: `url(${BackgroundImage})`,
  backgroundSize: '70%',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  marginBottom: 68,
  [theme.breakpoints.down('md')]: {
    marginBottom: 34,
  },
}))

const BoxWrapper = styled(Box)({
  backgroundColor: '#fff',
  boxShadow: '0px 10px 15px -3px #0000001A',
  borderRadius: '24px',
  marginTop: '55px',
})

export const HeroContainer = ({ children }: IHeroContainerProps) => {
  return (
    <HeroWrapper>
      <Container>
        <BoxWrapper sx={{ px: { xs: 3, md: 4, lg: 12 }, py: { xs: 4, md: 6 }, overflow: 'hidden' }}>
          {children}
        </BoxWrapper>
      </Container>
    </HeroWrapper>
  )
}
